.posts-list {
  max-width: 400px;
  /* max-width: calc(400px + 25px * 2); */
  width: 100%;
  margin: auto;
  margin: 100px auto;
}

#posts-list {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  margin: 50px;
}

.post-item-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 25px;
  background: #fff;
  cursor: pointer;
}

.post-item-container .post-item-thumbnail {
  display: flex;
  background-image: url("https://cdn2.steamgriddb.com/file/sgdb-cdn/thumb/fa095c5c2d2e4b39892e7e8fa7660646.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  height: 122.69px;
  width: 100%;
}

/* .post-item-container .post-item-thumbnail img { */
/* visibility: hiddewn; */
/* } */

.post-item-container p {
  color: #0a0a0a;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.post-item-container .post-content-quickie h1 {
}

.post-item-container h3 {
  color: #0a0a0a;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.post-content-container {
  max-width: 650px;
  margin: 5vh auto;
  font-size: 18px;
  color: #3e404b;
  display: flex;
  gap: 50px;
  line-height: 1.5;
  flex-direction: column;
}

.post-content-container>span {
  align-self: center;
  font-size: 24px;
}

.post-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* margin: 18px 0; */
}

.post-content p img {
  max-width: 100%;
}

.post-content-quickie h1 {
  font-size: 64px;
  font-weight: 800;
  color: #21232C;
}

.post-content-quickie p {
  width: 100%;
  max-width: 400px;
}

.post-content-header-img {
  background-image: url("https://steamuserimages-a.akamaihd.net/ugc/879751236391767201/BE4A06DDC8914C4B112C7D6A2FD460C26EB0A691/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false");
  background-size: cover;
  border-radius: 10px;
  width: 100%;
  height: 300px;
}

.post-content ol,
.post-content ul {
  margin-left: 25px;
  list-style: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.callout {
  padding-left: 25px;
  position: relative;
  border-left: solid 3.5px #000;
}